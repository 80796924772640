import { handleLogout } from '../../redux/actions/auth'
import { store } from '../../redux/storeConfig/store'

let timer = null

export const checkExpirationTime = (seconds = 86400, force = false) => {
  if (force) {
    localStorage.removeItem('expirationTime')
    if (timer) {
      clearTimeout(timer)
    }
  }
  
  let expirationTime = localStorage.getItem('expirationTime')
  if (!expirationTime) {
    expirationTime = new Date().getTime() + (seconds * 1000)
    localStorage.setItem('expirationTime', expirationTime)
  }

  const timeRemaining = expirationTime - new Date().getTime()

  if (timeRemaining <= 0) {
    store.dispatch(handleLogout())
    localStorage.removeItem('expirationTime')
    window.location.reload()
    return
  }

  if (timer) {
    clearTimeout(timer)
  }

  timer = setTimeout(() => {
    store.dispatch(handleLogout())
    localStorage.removeItem('expirationTime')
    window.location.reload()
  }, timeRemaining)

  return () => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  }
}