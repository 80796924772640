const initialState = {
  setupConfig: {
    isEmailConfirmed: null,
    isPasswordSecure: null,
    requiresMFASetup: null,
    qrCodeUrl: null,
    accessToken: null,
    refreshToken: null,
    session: null,
    email: null,
    document: null,
    profile: null
  }
}

const setupAuth = (state = initialState, action) => {
  switch (action.type) {
    case 'POST_SUCCESS_LOGIN':
      return {
        ...state,
        setupConfig: {
          isEmailConfirmed: action.data.isEmailConfirmed,
          isPasswordSecure: action.data.isPasswordSecure,
          requiresMFASetup: action.data.requiresMFASetup,
          qrCodeUrl: action.data.qrCodeUrl,
          accessToken: action.data.accessToken,
          refreshToken: action.data.refreshToken,
          session: action.data.session,
          email: action.data.email,
          document: action.data.document,
          profile: action.data.profile
        }
      }
    case 'UPDATE_SETUP_ATTRIBUTE':
      return {
        ...state,
        setupConfig: {
          ...state.setupConfig,
          [action.key]: action.value
        }
      }
    case 'SETUP_LOGOUT':
      return { ...initialState }
    default:
      return state
  }
}

export default setupAuth
