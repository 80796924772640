// ** Initial State
const initialState = {
  companyList: [],
  companySelectedId: ''
}

const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_COMPANY_LIST':
      return { ...state, companyList: action.value }
    case 'HANDLE_COMPANY_SELECTED':
      return { ...state, companySelectedId: action.value }
    case 'HANDLE_COMPANY_SELECTED_IS_GROUP':
      return { ...state, companySelectedIsGroup: action.value }
    case 'HANDLE_COMPANY_GROUP_ID':
      return { ...state, companyGroupId: action.value }
    case 'CLEAR_COMPANIES_STATE':
      return { ...initialState }
    default:
      return state
  }
}

export default companiesReducer