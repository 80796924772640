import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // LocalStorage
import rootReducer from '../reducers/rootReducer'
import alertMiddleware from '../../middlewares/Alert'

// ** Persist Configuration
const persistConfig = {
  key: 'root',        // Chave no localStorage
  storage,            // Tipo de armazenamento (localStorage)
  whitelist: ['setupAuth'] // Reducers que devem ser persistidos
}

const rootReducerWithReset = (state, action) => {
  if (action.type === 'RESET_REDUX') {
    state = undefined
  }
  return rootReducer(state, action)
}

// ** Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducerWithReset)

// ** Middleware
const middleware = [thunk, alertMiddleware, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create Store
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
)

// ** Persistor
const persistor = persistStore(store)

export const clearReduxState = async () => {
  await persistor.purge()
  store.dispatch({ type: 'RESET_REDUX' })
}

export { store, persistor }
