// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import { clearReduxState } from '../../storeConfig/store'
import { checkExpirationTime } from '../../../utility/context/CheckExpirationTimeSession'

const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    dispatch({
      type: 'LOGIN',
      data,
      config,
      [config.storageTokenKeyName]: data[config.storageTokenKeyName],
      [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
    })

    localStorage.setItem('userData', JSON.stringify(data))
    localStorage.setItem(config.storageTokenKeyName, JSON.stringify(data.accessToken))
    localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(data.refreshToken))
    checkExpirationTime(data.expiresIn, true)
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  const userData = JSON.parse(localStorage.getItem('userData')) || {}

  return dispatch => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/auth-closed/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${userData['accessToken']}`
      },
      body: JSON.stringify({
        accessToken: userData['accessToken']
      })
    }).catch((err) => console.error(err))
    dispatch({ type: 'LOGOUT', [config.storageTokenKeyName]: null, [config.storageRefreshTokenKeyName]: null })

    // ** Remove user, accessToken & refreshToken from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem(config.storageTokenKeyName)
    localStorage.removeItem(config.storageRefreshTokenKeyName)

    clearReduxState()
  }
}

export const handleConfirmedAuth = data => dispatch => dispatch({ type: 'LOGIN_CONFIRMED', data })

export const handleUpdateUserData = payload => dispatch => dispatch({ type: 'UPDATE_USER_DATA', payload })

export const handleClearAuthState = () => dispatch => dispatch({ type: 'CLEAR_AUTH_STATE' })